import Matomo from '@components/Matomo';
import Chatbot from '@components/Chatbot';

function CustomScripts() {
  return (
    <>
      <Matomo />
      {/* <Chatbot /> */}
    </>
  );
}

export default CustomScripts;
